import React from "react"
import FluidContainer from "../components/fluidContainer"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  z-index: 100;
  @media (max-width: 768px) {
    display: block;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  flex-basis: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`

const TextContainer = styled.div`
  width: 100%;
  text-align: ${props => (props.center ? "center" : "right")};
  flex-basis: ${props => (props.center ? "100%" : "70%")};
  @media (max-width: 768px) {
    text-align: center;
  }
`

const Banner = ({ img, children }) => {
  return (
    <FluidContainer style={{ paddingBottom: "0" }}>
      <BannerContainer>
        {img && (
          <ImageContainer>
            <Img fluid={img} />
          </ImageContainer>
        )}
        <TextContainer center={!img}>{children}</TextContainer>
      </BannerContainer>
    </FluidContainer>
  )
}

export default Banner
